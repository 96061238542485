import { useNavigate, NavLink, useParams, Outlet } from 'react-router-dom';
import Layout from './layout/Layout';

const SetLicense = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    let activeClassName = "rounded-full px-4 py-2 menu-active";
    let inactiveClassName = "rounded-full px-4 py-2 hover:font-bold hover:text-white hover:bg-[#2943D5] hover:duration-300 menu-inactive";

    return (
        <Layout>
            <div className="mt-5">
                <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#2943d5] cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={() => navigate('/my-license')}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <h1 className="text-xl">Atur Lisensi</h1>
                </div>
                <div className="flex items-center gap-4 mt-4">
                    <NavLink
                        to={`/my-license/set-license/${id}`} end
                        className={({ isActive }) =>
                            isActive ? activeClassName : inactiveClassName
                        }
                    >
                        <div>
                            Lisensi
                        </div>
                    </NavLink>
                    <NavLink
                        to={`info`}
                        className={({ isActive }) =>
                            isActive ? activeClassName : inactiveClassName
                        }
                    >
                        <div    >
                            Info Pembelian
                        </div>
                    </NavLink>
                </div>
                <Outlet />
            </div>
        </Layout>
    )
}

export default SetLicense