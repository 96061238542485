import {useRef, useState} from 'react';
import {useChangeDomainMutation} from '../../../../features/licenses/licensesApiSlice';

const ModalChangeDomain = ({show, data, closeDomainModal, reset}) => {

    const [messageChangeDomain, setMessageChangeDomain] = useState(null)

    const inputChangeDomainRef = useRef()

    const [changeDomain, {isLoading: changeDomainLoading}] = useChangeDomainMutation();

    const sanitizeDomain = (domain) => {
        return domain.replace(/^(https?:\/\/)?(http?:\/\/)?(www\.)?(\/.*)/, '').replace(/\/.*/, '');
    }

    const onChangeDomain = async () => {
        try {
            const domain = sanitizeDomain(inputChangeDomainRef.current.value);
            await changeDomain({
                license_key: data.licenseKey,
                old_domain: data.domain,
                new_domain: domain
            }).unwrap();
            setMessageChangeDomain(null);
            closeDomainModal()
            reset()
        } catch (error) {
            if (!error.data.success) setMessageChangeDomain(error.data.message);
        }
    }

    return (
        <div className={`relative z-10 ${!show ? 'hidden' : ''}`} aria-labelledby="modal-title" role="dialog"
             aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <div
                        className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-center">
                                <div
                                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                </div>
                                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Ganti
                                        Domain</h3>
                                </div>
                            </div>
                            <div className="mt-5">
                                <p className="text-sm text-gray-500">Nama Domain</p>
                                <div className="mt-1 flex gap-2 items-center">
                                    <img src="/images/icons/global-refresh.png" alt="browser" className="w-6 h-6"/>
                                    <span className="ml-2 text-lg text-gray-500 font-semibold">{data.domain}</span>
                                </div>
                            </div>
                            <div className="mt-5">
                                <p className="text-sm text-gray-500">Masukkan Domain Baru</p>
                                <div className="mt-1 relative">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className={`h-6 w-6 absolute top-2 left-1 ${messageChangeDomain ? 'text-red-400' : 'text-gray-400'}`}
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                                    </svg>
                                    <input ref={inputChangeDomainRef} type="text"
                                           className={`text-gray-900 placeholder-gray-500  border rounded-lg py-2 px-4 block w-full focus:outline-none focus:bg-white ${messageChangeDomain ? 'border-red-400 focus:border-red-500' : 'border-gray-400 focus:border-gray-500'} pl-8`}
                                           placeholder="domainbaru.com"/>
                                    {messageChangeDomain &&
                                        <span className="text-red-500 text-xs">{messageChangeDomain}</span>}
                                </div>
                            </div>
                            <div className="mt-5">
                                <div
                                    className="bg-green-200 p-3 border border-green-600 rounded-lg flex gap-2 items-start">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-green-600"
                                         viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd"
                                              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <p className="text-green-600 text-xs">Perubahan domain hanya bisa dilakukan hanya
                                        satu kali per 1 Domain, pastikan kamu telah mengetahui hal ini</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button onClick={() => onChangeDomain()} type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                {changeDomainLoading
                                    ? <svg className="animate-spin h-5 w-5 text-[#0b54f7] mr-2"
                                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    : <img src="/images/icons/Ganti-domain.png" alt="domain" className="mr-2"/>
                                }

                                Submit Change
                            </button>
                            <button
                                type="button"
                                onClick={() => closeDomainModal()}
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#0b54f7] text-base font-medium text-white hover:bg-[#164cbe] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0b54f7] sm:ml-3 sm:w-auto sm:text-sm"
                            >Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalChangeDomain