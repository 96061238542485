import OneSignal from 'react-onesignal';
import { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import Login from "./features/auth/Login";
import Layout from "./components/Layout";
import Home from "./components/member/Home";
import RequireAuth from './features/auth/RequireAuth';
import ErrorLogin from './components/error/ErrorLogin';
import MyLicense from './components/member/MyLicense';
import SetLicense from './components/member/SetLicense';
import SetLicense2 from './components/member/route/SetLicense2';
import InfoLicense from './components/member/route/InfoLicense';

function App() {
    useEffect(() => {
        OneSignal.init({
            appId: process.env.REACT_APP_ONESIGNAL_APP_ID
        });
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {/*Public routes*/}
                <Route index element={<Login />} />
                <Route path="error-login" element={<ErrorLogin />} />

                {/*Protected Routes*/}
                <Route element={<RequireAuth />}>
                    <Route path="home" element={<Home />} />
                    <Route path="my-license" element={<Layout />} >
                        <Route index element={<MyLicense />} />
                        <Route path="set-license/:id" element={<SetLicense />} >
                            <Route index element={<SetLicense2 />} />
                            <Route path="info" element={<InfoLicense />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
