import Search from './partials/Search';
import ButtonHelp from './partials/ButtonHelp';
import DropdownUser from './partials/DropdownUser';
import Logo from '../sidebar/partials/Logo';
import Menu from './../sidebar/partials/Menu';
import { useState } from 'react';

const Header = () => {

    const [mobileMenuShow, setMobileMenuShow] = useState(false)

    return (
        <>
            <header className="md:flex flex-wrap h-16 justify-between items-center w-full">
                <div className="grid grid-cols-3 gap-4 md:hidden">
                    <div className="flex items-center">
                        <div className="cursor-pointer hover:bg-gray-200 rounded-lg select-none p-1" onClick={() => setMobileMenuShow(!mobileMenuShow)}>
                            {!mobileMenuShow
                                ? <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                </svg>
                                : <svg className={`h-8 w-8 ${mobileMenuShow ? 'animate-[spin_0.5s_linear_0.3]' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            }
                        </div>
                    </div>
                    <div className="place-self-center">
                        <Logo />
                    </div>
                    <div className="flex justify-end items-center">
                        <DropdownUser />
                    </div>
                </div>
                <Search />
                <div className="md:flex justify-center items-center hidden">
                    <ButtonHelp />
                    <DropdownUser />
                </div>
            </header>

            {/* Mobile Menu */}
            <div className={`${mobileMenuShow ? 'md:hidden block' : 'hidden'}`}>
                <Menu />
            </div>
        </>
    )
}

export default Header