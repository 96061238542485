import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Layout from './layout/Layout'
import CardMyLicense from './partials/CardMyLicense'
import Pagination from './partials/Pagination'
import {useGetFilteredLicensesMutation} from "../../features/licenses/licensesApiSlice";
import {resetPageNumbers} from "../../features/licenses/licenseSlice";

const MyLicense = () => {
    const [page, setPage] = useState(1)
    const [isPaginateLoading, setIsPaginateLoading] = useState(false);
    const [filter, setFilter] = useState('all');

    const dispatch = useDispatch();
    const pageNumbers = useSelector((state) => state.license.pageNumbers)
    console.log(pageNumbers)

    const licenses = useSelector((state) => state.license)
    const [filterLicenses, {isLoading: licensesFilteredLoading}] = useGetFilteredLicensesMutation();

    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    const setPagePagination = (page) => {
        setPage(page)
    }

    const makePaginateLoading = (state) => {
        setIsPaginateLoading(state)
    }

    const filterLicense = async (filter) => {
        setFilter(filter)
        setPage(1)
        dispatch(resetPageNumbers())
        try {
            await filterLicenses({
                filter: filter === 'all' ? undefined : filter,
                populate: true
            });
        } catch (err) {
            console.log(err)
        }
    }

    const renderLoading = () => {
        if (isPaginateLoading || licensesFilteredLoading) {
            return (
                <div className="mt-10 flex justify-center items-center w-full h-full">
                    <svg className="animate-spin h-20 w-20 text-[#0b54f7]" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            );
        }
    }

    return (
        <Layout>
            <div className="mt-5">
                <div className="flex justify-between items-center">
                    <div className="flex gap-4 items-center">
                        <h2 className="font-bold text-xl text-gray-600">Lisensi Saya</h2>
                        <div className="flex items-center gap-2 text-md">
                            <button
                                className={`text-indigo-500 ${filter === 'all' && 'font-bold text-indigo-900'}`}
                                onClick={() => filterLicense('all')}
                            >All
                            </button>
                            <hr className="border-l border border-gray-400 h-4"/>
                            <button
                                className={`text-indigo-500 ${filter === 'lwcommerce' && 'font-bold text-indigo-900'}`}
                                onClick={() => filterLicense('lwcommerce')}
                            >LWCommerce
                            </button>
                            <hr className="border-l border border-gray-400 h-4"/>
                            <button
                                className={`text-indigo-500 ${filter === 'lwdonation' && 'font-bold text-indigo-900'}`}
                                onClick={() => filterLicense('lwdonation')}
                            >LWDonation
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <Pagination
                            page={page}
                            setPage={setPagePagination}
                            filter={filter}
                            setIsPaginateLoading={makePaginateLoading}
                            isPaginateLoading={isPaginateLoading}
                        />
                    </div>
                </div>
                {renderLoading()}
                {paginate(licenses.licenses, 10, page).map((license, index) => (
                    <div className="pt-4" key={(page - 1) * 10 + index + 1}>
                        <CardMyLicense data={license} id={(page - 1) * 10 + index + 1}/>
                    </div>
                ))}
            </div>
        </Layout>
    )
}

export default MyLicense