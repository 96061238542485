import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AUTH_URL
});


// TODO: Make token auto request refresh token if expired
const authQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await authQuery(args, api, extraOptions);
    // if (result?.error?.originalStatus === 401) {
    //     console.log('Re-authenticating...');
    //     // send refresh token request
    //     const refreshResult = await authQuery('/auth/refresh', api, extraOptions);
    //     console.log('Refresh result:', refreshResult);
    // }
    return result;
}

export const authSlice = createApi({
    reducerPath: 'authApi',
    baseQuery: authQueryWithReAuth,
    endpoints: builder => ({}),
})