import { useState, useEffect } from 'react';
import { useGetLicensesQuery } from '../../features/licenses/licensesApiSlice';
import { useSelector } from 'react-redux'
import Layout from './layout/Layout';
import CardHome from './partials/CardHome';
import CardHistory from './partials/CardHistory';
import ModalDomain from './partials/ModalDomain';
import Pagination from './partials/Pagination';

const Home = () => {
    const [loading, setLoading] = useState(false)
    const [loadingPreRelease, setLoadingPreRelease] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [modalData, setModalData] = useState({})
    const [latestReleaseLwcommerce, setLatestReleaseLwcommerce] = useState(null)
    const [latestReleaseVersion, setLatestReleaseVersion] = useState('')
    const [prePreleaseLwcommerce, setPrePreleaseLwcommerce] = useState(null)
    const [preReleaseVersion, setpreReleaseVersion] = useState('')
    const [page, setPage] = useState(1)
    const [isPaginateLoading, setIsPaginateLoading] = useState(false);

    const {
        data: licenses,
        isLoading,
        isSuccess
    } = useGetLicensesQuery({ populate: true });

    const licensesData = useSelector((state) => state.license)

    const setPagePagination = (page) => {
        setPage(page)
    }

    const makePaginateLoading = (state) => {
        setIsPaginateLoading(state)
    }

    // TODO: Move to Redux
    useEffect(() => {
        const fetchData = async () => {
            const serverLatestRelease = "https://api.github.com/repos/lokuswp/lwcommerce/releases/latest";
            const serverPreRelease = "https://api.github.com/repos/lokuswp/lwcommerce/releases";

            const latestRelease = await fetch(serverLatestRelease);
            const preReleases = await fetch(serverPreRelease);

            const dataLatestRelease = await latestRelease.json();
            const dataPreRelease = await preReleases.json();

            // set url
            setLatestReleaseLwcommerce(dataLatestRelease.assets[0].browser_download_url)
            const preRelease = dataPreRelease.find(item => {
                const date = new Date(item.published_at);
                date.setDate(date.getDate() + 7);

                // return item that meet the conditions
                return item.prerelease === true && date > new Date()
            });
            if (preRelease) {
                setPrePreleaseLwcommerce(preRelease.assets[0].browser_download_url)
                setpreReleaseVersion(preRelease.tag_name)
            }

            // set version (tag_name)
            setLatestReleaseVersion(dataLatestRelease.tag_name)
        }

        fetchData()
    }, [])

    const downloadLwcommercePreRelease = async () => {
        setLoadingPreRelease(true);

        setTimeout(() => {
            window.location.href = prePreleaseLwcommerce;
            setLoadingPreRelease(false);
        }, 1500);
    }

    const downloadLwcommerce = async () => {
        setLoading(true);

        setTimeout(() => {
            window.location.href = latestReleaseLwcommerce;
            setLoading(false);
        }, 1500);
    }

    const getNavigatorLanguage = () => {
        if (navigator.languages && navigator.languages.length) {
            return navigator.languages[0];
        } else {
            return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
        }
    }

    const formatDate = (date) => {
        const dateObj = new Date(date);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return dateObj.toLocaleDateString(getNavigatorLanguage(), options);
    }

    const getTotalDomain = () => {
        let totalDomain = 0;
        let totalSubdomain = 0;
        if (licensesData.licenses) {
            for (let i = 0; i < licensesData.licenses.length; i++) {
                if (licensesData.licenses[i].domains) {
                    totalDomain += licensesData.licenses[i].domains.length;
                    totalSubdomain += licensesData.licenses[i].subdomains.length;
                }
            }
        }
        return totalDomain + totalSubdomain;
    }

    const openModal = (dataModal) => {
        setModalData(dataModal);
        setModalShow(true);
    }

    const closeModal = () => {
        setModalShow(false);
    }

    const reset = () => {
        setModalShow(false);
        setModalData({});
    }

    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    const cardDomain = [];


    if (licensesData.licenses) {
        const paginateLicenses = paginate(licensesData.licenses, 10, page);
        for (const license of paginateLicenses) {
            if (license.domains) {
                for (const domain of license.domains) {
                    cardDomain.push(<CardHistory
                        domain={domain}
                        date={formatDate(license.created_at)}
                        status={license.status}
                        licenseKey={license.license_key}
                        openModal={openModal}
                        key={`${domain}${new Date(license.created_at)}`} />)
                }
                for (const subdomain of license.subdomains) {
                    cardDomain.push(<CardHistory
                        domain={subdomain}
                        date={formatDate(license.created_at)}
                        status={license.status}
                        licenseKey={license.license_key}
                        openModal={openModal}
                        key={`${subdomain}${new Date(license.created_at)}`} />)
                }
            }
        }
    }

    return (
        <Layout>
            <div className="mt-5 flex gap-5">
                {isLoading &&
                    <div className="mt-10 flex justify-center items-center w-full h-full">
                        <svg className="animate-spin h-20 w-20 text-[#0b54f7]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                }
                {isSuccess &&
                    <div className="w-full">
                        <h2 className="font-bold text-xl text-gray-600">Detail Lisensi</h2>
                        <div className="pt-2 flex flex-wrap gap-6 sm:flex-nowrap">
                            <CardHome text="Total Lisensi" value={licenses.scannedCount} classStyle="bg-[#e6eeff]" imgSrc="/images/icons/Expired.png" />
                            <CardHome text="Jumlah Website" value={getTotalDomain()} classStyle="bg-[#fbf7f0]" imgSrc="/images/icons/total-lisensi.png" />
                        </div>
                        <div className="flex flex-col-reverse md:flex-row gap-7">
                            <div className="w-full">
                                <div className="mt-9 flex justify-between">
                                    <h2 className="font-bold text-xl text-gray-600 ">Riwayat</h2>
                                    <div className="flex items-center gap-2">
                                        <Pagination
                                            page={page}
                                            setPage={setPagePagination}
                                            setIsPaginateLoading={makePaginateLoading}
                                            isPaginateLoading={isPaginateLoading}
                                        />
                                        <button type="button" className="text-sm text-gray-500">See All</button>
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-4">
                                    {isPaginateLoading &&
                                        <div className="mt-10 flex justify-center items-center w-full h-full">
                                            <svg className="animate-spin h-20 w-20 text-[#0b54f7]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </div>
                                    }
                                    {cardDomain}
                                </div>
                            </div>
                            <div className="md:mt-20 mt-10 block md:hidden">
                                <div className="p-5 bg-[#0b54f7] rounded-lg text-white md:w-72 flex flex-col">
                                    <h1 className="font-semibold text-2xl mb-2">LWCommerce</h1>
                                    <p className="text-sm mb-5">Plugin Jual Beli / Toko Online untuk CMS WordPress, dengan plugin ini anda bisa mulai berjualan dengan aturan anda di website anda sendiri.</p>
                                    <button className="py-2 px-9 rounded-lg bg-white hover:bg-blue-100 text-[#0b54f7] flex justify-center" onClick={downloadLwcommerce}>
                                        {loading ?
                                            <svg className="animate-spin h-5 w-5 text-[#0b54f7]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            :
                                            <span>Download <small>({latestReleaseVersion})</small></span>
                                        }
                                    </button>
                                </div>
                                {prePreleaseLwcommerce &&
                                    <div className="p-5 bg-[#ce8517] rounded-lg text-white md:w-72 mt-5 flex flex-col">
                                        <h1 className="font-semibold text-2xl mb-2">LWCommerce (pre-release)</h1>
                                        <p className="text-sm mb-5">Plugin Jual Beli / Toko Online untuk CMS WordPress, dengan plugin ini anda bisa mulai berjualan dengan aturan anda di website anda sendiri.</p>
                                        <button className="py-2 px-9 rounded-lg bg-white hover:bg-blue-100 text-[#ce8517] flex justify-center" onClick={downloadLwcommercePreRelease}>
                                            {loadingPreRelease ?
                                                <svg className="animate-spin h-5 w-5 text-[#ce8517]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                :
                                                <span>Download <small>({preReleaseVersion})</small></span>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {isSuccess &&
                    <div className="md:mt-9 mt-10 hidden md:block">
                        <div className="p-5 bg-[#0b54f7] rounded-lg text-white md:w-72 flex flex-col">
                            <h1 className="font-semibold text-2xl mb-2">LWCommerce</h1>
                            <p className="text-sm mb-5">Plugin Jual Beli / Toko Online untuk CMS WordPress, dengan plugin ini anda bisa mulai berjualan dengan aturan anda di website anda sendiri.</p>
                            <button className="py-2 px-9 rounded-lg bg-white hover:bg-blue-100 text-[#0b54f7] flex justify-center" onClick={downloadLwcommerce}>
                                {loading ?
                                    <svg className="animate-spin h-5 w-5 text-[#0b54f7]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    :
                                    <span>Download <small>({latestReleaseVersion})</small></span>
                                }
                            </button>
                        </div>
                        {prePreleaseLwcommerce &&
                            <div className="p-5 bg-[#ce8517] rounded-lg text-white md:w-72 mt-5 flex flex-col">
                                <h1 className="font-semibold text-2xl mb-2">LWCommerce (pre-release)</h1>
                                <p className="text-sm mb-5">Plugin Jual Beli / Toko Online untuk CMS WordPress, dengan plugin ini anda bisa mulai berjualan dengan aturan anda di website anda sendiri.</p>
                                <button className="py-2 px-9 rounded-lg bg-white hover:bg-blue-100 text-[#ce8517] flex justify-center" onClick={downloadLwcommercePreRelease}>
                                    {loadingPreRelease ?
                                        <svg className="animate-spin h-5 w-5 text-[#ce8517]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        :
                                        <span>Download <small>({preReleaseVersion})</small></span>
                                    }
                                </button>
                            </div>
                        }
                    </div>
                }
            </div>
            <ModalDomain show={modalShow} data={modalData} reset={reset} closeModal={closeModal} />
        </Layout>
    )
}

export default Home;