const Modal = ({ show, data, closeModal, openModalDomain }) => {

    const checkSubDomain = (domain) => {
        if (domain) {
            const subdomain = domain.split('.');
            return subdomain.length > 2;
        }
    }

    return (
        <div className={`relative z-10 ${!show ? 'hidden' : ''}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-center">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Detail Lisensi</h3>
                                </div>
                            </div>
                            <div className="mt-5 flex justify-between items-center">
                                <p className="text-sm text-gray-500">Nama Domain</p>
                                <p className="text-sm text-[#FF5F2E] font-semibold">{data.domain}</p>
                            </div>
                            <hr className="my-4 border border-[#A6B0C3]" />
                            <div className="flex justify-between items-center">
                                <p className="text-sm text-gray-500">Status</p>
                                <div className="bg-[#09B699] text-white py-1 px-6 rounded-full">{data.status}</div>
                            </div>
                            <hr className="my-4 border border-[#A6B0C3]" />
                            <div className="flex justify-between items-center">
                                <p className="text-sm text-gray-500">Tanggal Instalasi</p>
                                <p className="text-sm ">{data.date}</p>
                            </div>
                            <hr className="my-4 border border-[#A6B0C3]" />
                            <div className="flex justify-between items-center">
                                <p className="text-sm text-gray-500">Kode Lisensi</p>
                                <p className="text-sm">{data.licenseKey}</p>
                            </div>
                            <hr className="my-4 border border-[#A6B0C3]" />
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            {!checkSubDomain(data.domain) ?
                                <button type="button" onClick={() => openModalDomain({ domain: data.domain, licenseKey: data.licenseKey })} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                    <img src="/images/icons/Ganti-domain.png" alt="domain" className="mr-2" />
                                    Ganti Domain
                                </button>
                                : ''}
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#0b54f7] text-base font-medium text-white hover:bg-[#164cbe] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0b54f7] sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => closeModal()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal