import {useState} from "react";

function CopyToClipboard({textToCopy}) {
    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(text) {
        navigator.clipboard.writeText(text).then(function () {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }, function () {
            /* clipboard write failed */
        });
    }

    return (
        <>
            <button
                type="button" onClick={() => copyTextToClipboard(textToCopy)}
                title="copy to clipboard"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"/>
                </svg>
            </button>
            {isCopied && <span className="text-green-900">Copied!</span>}
        </>
    );
}

export default CopyToClipboard;