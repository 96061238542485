import { useState } from 'react'
import Modal from './modal/Modal';
import ModalChangeDomain from './modal/ModalChangeDomain';

const ModalDomain = ({ show, data, reset, closeModal }) => {

    const [modalShowDomain, setModalShowDomain] = useState(false)
    const [modalDomain, setModalDomain] = useState('')

    const opeModalDomain = (data) => {
        setModalDomain(data);
        setModalShowDomain(true);
    }

    const closeModalDomain = () => {
        setModalShowDomain(false);
    }

    const resetState = () => {
        reset();
        setModalShowDomain(false);
        setModalDomain('');
    }


    return (
        <>
            <Modal
                show={show}
                data={data}
                closeModal={closeModal}
                openModalDomain={opeModalDomain}
            />
            <ModalChangeDomain
                show={modalShowDomain}
                data={modalDomain}
                closeDomainModal={closeModalDomain}
                reset={resetState}
            />
        </>
    )
}

export default ModalDomain