import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        user: null,
    },
    reducers: {
        setCredentials: (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        logOut: (state) => {
            state.token = null;
            state.user = null;
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions;

export const selectCurrentUser = state => state.auth.user;
export const selectCurrentToken = state => state.auth.token;

export default authSlice.reducer;