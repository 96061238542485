import { Link } from "react-router-dom"

const Logo = () => {
    return (
        <Link to="/home">
            <img src="/images/icons/avatar.png" alt="avatar" />
        </Link>
    )
}

export default Logo