const CardHome = ({ text, classStyle, imgSrc, value }) => {
    return (
        <div className={`w-full rounded-lg flex justify-between items-center p-4 ${classStyle}`}>
            <div>
                <h4 className="text-[#808A9D] text-[16px]">{text}</h4>
                <h3 className="font-semibold mt-1 text-lg">{value}</h3>
            </div>
            <div>
                <img src={imgSrc} alt="icon" />
            </div>
        </div>
    )
}

export default CardHome