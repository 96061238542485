import { authSlice } from "../../app/api/authSlice";

export const authApiSlice = authSlice.injectEndpoints({
    endpoints: builder => ({
        introspectAccessToken: builder.mutation({
            query: ({ client_id, token }) => ({
                url: `/oauth2/introspect`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: {
                    client_id,
                    token,
                }
            })
        }),
        retrieveUser: builder.mutation({
            query: ({ userId }) => ({
                url: `/api/user/${userId}`,
                method: 'GET',
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                }
            })
        }),
    })
})

export const {
    useIntrospectAccessTokenMutation,
    useRetrieveUserMutation,
} = authApiSlice