import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authSlice } from './api/authSlice';
import { licenseSlice } from "./api/licenseSlice";
import authReducer from "../features/auth/authSlice";
import licenseReducer from "../features/licenses/licenseSlice";

// combine reducers
const rootReducer = combineReducers({
    [authSlice.reducerPath]: authSlice.reducer,
    [licenseSlice.reducerPath]: licenseSlice.reducer,
    auth: authReducer,
    license: licenseReducer,
});

// configure store
export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(authSlice.middleware).concat(licenseSlice.middleware),
    devTools: process.env.REACT_APP_ENV === 'local',
})