import { NavLink } from "react-router-dom"

const MenuItem = ({ children, link, name, state }) => {
    let activeClassName = "menu-item menu-active";
    let inactiveClassName = "menu-item menu-inactive";

    return (
        <NavLink
            to={link}
            className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
            }
        >
            <div className="flex px-3 items-center">
                {children}
                <div className="leading-4 indent-0 ml-2">{name}</div>
            </div>
        </NavLink>
    )
}

export default MenuItem