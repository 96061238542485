import React from 'react'

const Search = () => {
    return (
        <div className="bg-[#f6f8f9] h-12 rounded-full md:flex items-center justify-center relative w-64 hidden">
            <input type="text" name="searchDomain" className="rounded-full bg-[#f6f8f9] text-[#8394B1] border-white outline-0 border-0 ml-3 pl-6 pr-1 placeholder:text-[#8394B1] placeholder:text-sm" placeholder="Cari domain..." />
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute top-3 left-3 text-[#8394B1]" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
        </div>
    )
}

export default Search