import {licenseSlice} from '../../app/api/licenseSlice';
import {setLicenses} from './licenseSlice';

export const licenseApiSlice = licenseSlice.injectEndpoints({
    endpoints: builder => ({
        getLicenses: builder.query({
            query: (args) => ({
                url: '/license',
                method: 'GET',
                params: {
                    populate: args?.populate,
                    limit: args?.limit,
                },
            }),
            providesTags: ['Licenses'],
            onQueryStarted: async (args, {dispatch, queryFulfilled}) => {
                try {
                    const {data} = await queryFulfilled
                    dispatch(setLicenses(data))
                } catch (err) {
                    alert('Something went wrong! Please try again later.');
                }
            }
        }),
        getFilteredLicenses: builder.mutation({
            query: (args) => ({
                url: '/license',
                method: 'GET',
                params: {
                    populate: args?.populate,
                    limit: args?.limit,
                    filter: args.filter,
                }
            }),
            providesTags: ['Licenses'],
            onQueryStarted: async (args, {dispatch, queryFulfilled}) => {
                try {
                    const {data} = await queryFulfilled
                    dispatch(setLicenses(data))
                } catch (err) {
                    alert('Something went wrong! Please try again later.');
                }
            }
        }),
        getNextLicenses: builder.mutation({
            query: (args) => ({
                url: '/license',
                method: 'GET',
                params: {
                    populate: args?.populate,
                    limit: args?.limit,
                },
                headers: {
                    lastKey: args?.lastKey,
                }
            }),
            providesTags: ['Licenses'],
            onQueryStarted: async (args, {dispatch, queryFulfilled, getState}) => {
                const oldLicenses = getState().license;
                try {
                    const {data} = await queryFulfilled

                    // Append new licenses to old licenses
                    const newLicenses = [...oldLicenses.licenses, ...data.data];
                    const newCount = oldLicenses.count + data.count;
                    const newLastKey = data.lastKey;

                    dispatch(setLicenses({
                        data: newLicenses,
                        lastKey: newLastKey,
                        count: newCount,
                        scannedCount: data.scannedCount
                    }))
                } catch (err) {
                    alert('Something went wrong! Please try again later.');
                }
            }
        }),
        getLicenseByKey: builder.query({
            query: (licenseKey) => `/license/${licenseKey}`,
            providesTags: ['Licenses'],
        }),
        changeDomain: builder.mutation({
            query: domains => ({
                url: '/license/change-domain',
                method: 'PATCH',
                body: {...domains}
            }),
            invalidatesTags: ['Licenses'],
        })
    })
})

export const {
    useGetLicensesQuery,
    useGetFilteredLicensesMutation,
    useGetLicenseByKeyQuery,
    useChangeDomainMutation,
    useGetNextLicensesMutation,
} = licenseApiSlice;