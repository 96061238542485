import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const liceseQuery = fetchBaseQuery({
    baseUrl: 'https://digitalcraft.id/api/v1',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const licenseSlice = createApi({
    reducerPath: 'licenseApi',
    baseQuery: liceseQuery,
    tagTypes: ['Licenses'],
    endpoints: builder => ({}),
})