import { useIntrospectAccessTokenMutation, useRetrieveUserMutation } from "./authApiSlice";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCredentials } from "./authSlice";
import { useNavigate } from "react-router-dom";
import PacManLoadingAwokawokk from './../../components/member/layout/header/partials/PacManLoadingAwokawokk';

const appId = process.env.REACT_APP_APP_ID;

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

const token = params?.token;

if (!token) window.location.replace(process.env.REACT_APP_LOGIN_URL);

const Login = () => {
    const [IntrospectAccessToken] = useIntrospectAccessTokenMutation();
    const [retrieveUser] = useRetrieveUserMutation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        const getUser = async () => {
            try {
                const introspect = await IntrospectAccessToken({ client_id: appId, token });
                const user = await retrieveUser({ userId: introspect.data.sub });

                dispatch(setCredentials({ user: user.data.user, token }));
                navigate('/home');
            } catch (error) {
                console.error(error);
            }
        }

        getUser();

    }, [])


    return (
        <PacManLoadingAwokawokk />
    )
}

export default Login;