function ErrorLogin() {
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <div className="flex flex-col items-center">
                <h1 className="text-5xl font-bold text-red-700">ERROR LOGIN</h1>
                <h3 className="text-2xl text-gray-700">Something went wrong!</h3>
                <h4 className="text-xl text-gray-700">Please try again later.</h4>
            </div>
        </div>
    )
}

export default ErrorLogin