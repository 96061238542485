import CardHome from './../partials/CardHome';
import CardHistory from './../partials/CardHistory';
import { useGetLicenseByKeyQuery } from '../../../features/licenses/licensesApiSlice';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalDomain from '../partials/ModalDomain';

const SetLicense2 = () => {
    const [modalShow, setModalShow] = useState(false)
    const [modalData, setModalData] = useState({})

    let { id } = useParams();

    const {
        data: license,
        isLoading,
        isSuccess
    } = useGetLicenseByKeyQuery(id);

    const getNavigatorLanguage = () => {
        if (navigator.languages && navigator.languages.length) {
            return navigator.languages[0];
        } else {
            return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
        }
    }

    const formatDate = (date) => {
        const dateObj = new Date(date);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return dateObj.toLocaleDateString(getNavigatorLanguage(), options);
    }

    const openModal = (dataModal) => {
        setModalData(dataModal);
        setModalShow(true);
    }

    const closeModal = () => {
        setModalShow(false);
    }

    const reset = () => {
        setModalShow(false);
        setModalData({});
    }

    const DiasDelMes = (date) => {
        date = new Date(date);
        return 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate();
    }

    // difference in years, months, and days between 2 dates
    const difDate = (dIni, dFin) => {
        let dAux, nAnos, nMeses, nDias, cRetorno;
        // final date always greater than the initial
        if (dIni > dFin) {
            dAux = dIni
            dIni = dFin
            dFin = dAux
        }
        // calculate years
        nAnos = dFin.getFullYear() - dIni.getFullYear()
        // translate the initial date to the same year that the final
        dAux = new Date(dIni.getFullYear() + nAnos, dIni.getMonth(), dIni.getDate())
        // Check if we have to take a year off because it is not full
        if (dAux > dFin) {
            --nAnos
        }
        // calculate months
        nMeses = dFin.getMonth() - dIni.getMonth()
        // We add in months the part of the incomplete Year
        if (nMeses < 0) {
            nMeses = nMeses + 12
        }
        // Calculate days
        nDias = dFin.getDate() - dIni.getDate()
        // We add in days the part of the incomplete month
        if (nDias < 0) {
            nDias = nDias + DiasDelMes(dIni)
        }
        // if the day is greater, we quit the month
        if (dFin.getDate() < dIni.getDate()) {
            if (nMeses === 0) {
                nMeses = 11
            } else {
                --nMeses
            }
        }
        cRetorno = { "year": nAnos, "month": nMeses, "day": nDias }
        return cRetorno
    }

    const cardDomain = [];
    let diff;

    if (isSuccess) {
        for (const domain of license.data.domains) {
            cardDomain.push(<CardHistory
                domain={domain}
                date={formatDate(license.data.created_at)}
                status={license.data.status}
                licenseKey={license.data.license_key}
                openModal={openModal}
                key={`${domain}${new Date(license.data.created_at)}`} />)
        }
        for (const subdomain of license.data.subdomains) {
            cardDomain.push(<CardHistory
                domain={subdomain}
                date={formatDate(license.data.created_at)}
                status={license.data.status}
                licenseKey={license.data.license_key}
                openModal={openModal}
                key={`${subdomain}${new Date(license.data.created_at)}`} />)
        }

        diff = difDate(new Date(license.data.expired_at), new Date());
    }


    return (
        <>
            <div className="mt-5">
                {isLoading &&
                    <div className="mt-10 flex justify-center items-center">
                        <svg className="animate-spin h-20 w-20 text-[#0b54f7]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                }
                {isSuccess &&
                    <>
                        <h2 className="font-bold text-xl text-gray-600">Lisensi Saya</h2>
                        <div className="pt-2 flex flex-wrap gap-5 sm:flex-nowrap">
                            <CardHome text="Terpakai" value={license.data.domains.length + license.data.subdomains.length} classStyle="bg-[#e6eeff]" imgSrc="/images/icons/Expired.png" />
                            <CardHome text="Slot Website" value={license.data.domain_limit + license.data.subdomain_limit} classStyle="bg-[#fbf7f0]" imgSrc="/images/icons/total-lisensi.png" />
                            <CardHome text="Aktif Hingga" value={`${diff.year ? `${diff.year} Tahun` : ''}${diff.month ? `${diff.month} Bulan` : ''}${diff.day ? `, ${diff.day} Hari` : ''}`} classStyle="bg-[#e5f8f7]" imgSrc="/images/icons/calendar.png" />
                        </div>
                        <h2 className="font-bold text-xl text-gray-600 mt-5">Lisensi Terpakai</h2>
                        <div className="flex flex-wrap gap-4">
                            {cardDomain}
                        </div>
                    </>
                }
            </div>
            <ModalDomain show={modalShow} data={modalData} reset={reset} closeModal={closeModal} />
        </>
    )
}

export default SetLicense2