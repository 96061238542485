const CardHistory = ({ domain, date, status, licenseKey, openModal }) => {
    return (
        <div className="rounded-lg p-3 border border-[#A6B0C3] flex-auto w-72 mt-4">
            <a href={`https://${domain}`} target="_blank" rel="noreferrer" className="cursor-pointer">
                <div className="flex items-center hover:underline hover:decoration-[#FF5F2E]">
                    <img src="/images/icons/1.png" className="w-5 h-5" alt="url" />
                    <h1 className="text-[#FF5F2E] text-lg ml-1">{domain}</h1>
                </div>
            </a>
            <div className="flex justify-between items-center mt-4">
                <span className="text-sm text-gray-500">Tanggal Instalasi</span>
                <span>{date}</span>
            </div>
            <hr className="border border-[#9AACCB] my-2" />
            <div className="flex justify-between items-center mt-4">
                <span className="text-sm text-gray-500">Status Lisensi</span>
                <div className="bg-[#09B699] text-white py-1 px-6 rounded-full">{status}</div>
            </div>
            <hr className="border border-[#9AACCB] my-2" />
            <button className="w-full bg-[#2943D5] hover:bg-[#1e35b6] text-white rounded-lg py-3 " onClick={() => openModal({ domain, date, status, licenseKey })}>
                Lihat Detail
            </button>
        </div>
    )
}

export default CardHistory