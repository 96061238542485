import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from './authSlice';
import PacManLoadingAwokawokk from './../../components/member/layout/header/partials/PacManLoadingAwokawokk';

const RequireAuth = () => {
    const token = useSelector(selectCurrentToken);

    return (
        token
            ? <Outlet />
            : <PacManLoadingAwokawokk />
    );
}

export default RequireAuth;