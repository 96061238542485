import {useSelector, useDispatch} from 'react-redux'
import {setPageNumbers} from '../../../features/licenses/licenseSlice';
import {useGetNextLicensesMutation} from '../../../features/licenses/licensesApiSlice';


const Pagination = ({page, setPage, setIsPaginateLoading, isPaginateLoading, filter}) => {
    const dispatch = useDispatch();
    const pageNumbers = useSelector((state) => state.license.pageNumbers)
    const licensesData = useSelector((state) => state.license)

    const [getNextLicenses] = useGetNextLicensesMutation();

    const nextLicense = async () => {
        setPage((page) => page + 1);

        if (licensesData.licenses.length > page * 10) return;
        setIsPaginateLoading(true);
        const lastKey = JSON.stringify(licensesData.lastKey);
        await getNextLicenses({
            lastKey,
            populate: true,
            filter: filter === 'all' ? undefined : filter
        });
        setIsPaginateLoading(false);
        dispatch(setPageNumbers(page + 1))
    }

    const prevLicense = async () => {
        if (page === 1) return;
        setPage(page - 1);
    }

    // make function to limit paginate number shown in the page
    const getPageNumbers = (pageNumbers) => {
        if (licensesData.licenses) {
            // limit page number shown in the page to only 5
            if (pageNumbers.length > 5) {
                return pageNumbers.slice(pageNumbers.length - 5, pageNumbers.length);
            }
            return pageNumbers;
        }
        return [1];
    }

    return (
        <>
            {licensesData.scannedCount > 0 &&
                <>
                    {page > 1 &&
                        <button className="rounded-full p-1 hover:bg-gray-200 active:bg-gray-300" onClick={prevLicense}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    }
                    <div className="flex items-center gap-1">
                        {pageNumbers.length > 6 &&
                            <>
                                <button className="rounded-full p-1 hover:bg-gray-200 active:bg-gray-300"
                                        onClick={() => setPage(1)}>1
                                </button>
                                <p> ...</p>
                            </>
                        }
                        {getPageNumbers(pageNumbers).map((pageNumber, index) => (
                            <button
                                className={`rounded-full p-1 hover:bg-gray-200 active:bg-gray-300 ${pageNumber === page && 'font-bold'}`}
                                key={index} onClick={() => setPage(pageNumber)}>{pageNumber}</button>
                        ))}
                        {licensesData.scannedCount !== licensesData.count && <p>...</p>}
                    </div>
                    {page !== licensesData.scannedCount &&
                        <button className="rounded-full p-1 hover:bg-gray-200 active:bg-gray-300" onClick={nextLicense}
                                disabled={isPaginateLoading}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    }
                </>
            }
        </>
    )
}

export default Pagination