import {Link} from "react-router-dom"
import CopyToClipboard from "./CopyToClipboard";

const CardMyLicense = ({data, id}) => {
    const getNavigatorLanguage = () => {
        if (navigator.languages && navigator.languages.length) {
            return navigator.languages[0];
        } else {
            return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
        }
    }

    const formatDate = (date) => {
        const dateObj = new Date(date);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return dateObj.toLocaleDateString(getNavigatorLanguage(), options);
    }

    // calculate days between two dates
    const daysBetween = (date1, date2) => {
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = new Date(date1);
        const secondDate = new Date(date2);
        const diff = (firstDate.getTime() - secondDate.getTime()) / (oneDay);
        return diff < 0 ? timeFormat(Math.round(Math.abs(diff)), 'day', 'id') : 0 + " Hari";
    }

    const timeFormat = (time, format, locales) => {
        const rtf1 = new Intl.RelativeTimeFormat(locales, {style: 'long'});
        return rtf1.format(time, format);
    }

    const numberWithDots = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    function ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const domains = [];

    if (data.domains) {
        for (let i = 0; i < data.domains.length; i++) {
            domains.push(<a href={`https://${data.domains[i]}`}
                            className="text-xs font-semibold text-white bg-[#2943d5] hover:bg-[#2136aa] px-3 py-1 rounded-full"
                            key={i}>{data.domains[i]}</a>);
            if (i === 2) {
                domains.push(<span
                    className="text-xs font-semibold text-white bg-[#2943d5] hover:bg-[#2136aa] px-3 py-1 rounded-full">+ {i}</span>);
                break;
            }
        }
    }

    return (
        <>
            <div className="border border-[#A6B0C3] rounded-lg p-5">
                <div className="flex gap-3 justify-between items-center">
                    <div className="flex flex-wrap gap-3">
                        <span className="text-sm font-semibold">#{id}</span>
                        <span className="text-sm text-gray-500">{formatDate(data.created_at)}</span>
                        <div className="border-r border-[#A6B0C3]"></div>
                        <div className="flex gap-1">
                            <img src="/images/icons/lifetime.png" alt="license type"/>
                            <span
                                className="text-sm text-[#FF5F2E]">Lisensi {data.package.toLowerCase()
                                .split('_')
                                .join(' ')
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                            }
                            </span>
                        </div>
                        <div className="border-r border-[#A6B0C3]"></div>
                        <div className="flex gap-1">
                            <img src="/images/icons/key.png" alt="website"/>
                            <span className="text-sm text-gray-500">Website</span>
                        </div>
                        <div className="border-r border-[#A6B0C3]"></div>
                        <div className="flex gap-1">
                            <img src="/images/icons/total-website.png" alt="date"/>
                            <span
                                className="text-sm text-gray-500">Masa aktif: {daysBetween(new Date(), data.expire_at)}</span>
                        </div>
                    </div>
                    <div
                        className={`${data.status === 'used' ? 'bg-[#09B699]' : 'bg-gray-400'} text-white py-1 px-6 rounded-full text-sm`}>{data.status}</div>
                </div>
                <hr className="my-4 border border-[#A6B0C3]"/>
                <div className="flex mt-6">
                    <div className="flex items-center gap-3 ">
                        <img src="/images/icons/unsplash_zs98a0DtKL4.png" alt="icon"/>
                        <div className="flex flex-col">
                            <h4>{data.slug.name}</h4>
                            <p className="text-gray-500 text-sm">{data.slug.currency === 'IDR' ? 'Rp' : 'Rp'}{numberWithDots(data.slug.price)} -
                                ({daysBetween(new Date(), data.expire_at).replace('dalam', '').trim()})</p>
                        </div>
                    </div>
                </div>
                <div className="my-3 text-xs flex flex-row items-center gap-4">
                    <div className="flex flex-row">
                        <span className="font-semibold">License key:</span> <span
                        className="text-[#FF5F2E]">{data.license_key}</span>
                    </div>
                    <CopyToClipboard textToCopy={data.license_key}/>
                </div>
                <div className="mt-5 flex md:flex-row flex-col gap-6 justify-between md:items-center items-start">
                    <div className="flex gap-2">
                        {domains}
                    </div>
                    <div className="flex gap-2">
                        {data.slug.slug === 'lwdonation' &&
                            <a href={`https://lokuswp.id/checkout/?slug=lwdonation&email=${data.user_email}&coupon=RENEWAL40`}
                               target="_blank"
                               className="text-sm border rounded-lg py-2 px-4 flex gap-2 focus:outline-none items-center text-[#2943d5] border-[#2943d5] focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                               rel="noreferrer">Renew</a>
                        }
                        <a href={data.slug.update_version ? data.slug.update_version.slice(-1)[0].download_url : '#'}
                           className={`text-sm border rounded-lg py-2 px-4 flex gap-2 focus:outline-none items-center  ${data.slug.update_version ? 'text-[#2943d5] border-[#2943d5] focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : 'text-gray-300 border-gray-300 '}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 10v6m0 0l-3-3m3 3l3-3M3 17V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"/>
                            </svg>
                            Download Plugin
                        </a>
                        <Link to={`/my-license/set-license/${data.license_key}`}>
                            <div className="bg-[#2943d5] hover:bg-[#2136aa] text-sm py-2 px-4 text-white rounded-lg">
                                Atur Lisensi
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardMyLicense
