import MenuItem from './MenuItem';

const Menu = () => {
    return (
        <>
            <div className="flex flex-col gap-3 justify-between">
                <MenuItem link="/home" name="Beranda" state="active" >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" >
                        <path d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z" ></path>
                        <path d="M18.67 2H16.77C14.59 2 13.44 3.15 13.44 5.33V7.23C13.44 9.41 14.59 10.56 16.77 10.56H18.67C20.85 10.56 22 9.41 22 7.23V5.33C22 3.15 20.85 2 18.67 2Z" ></path>
                        <path d="M18.67 13.4302H16.77C14.59 13.4302 13.44 14.5802 13.44 16.7602V18.6602C13.44 20.8402 14.59 21.9902 16.77 21.9902H18.67C20.85 21.9902 22 20.8402 22 18.6602V16.7602C22 14.5802 20.85 13.4302 18.67 13.4302Z" ></path>
                        <path d="M7.24 13.4302H5.34C3.15 13.4302 2 14.5802 2 16.7602V18.6602C2 20.8502 3.15 22.0002 5.33 22.0002H7.23C9.41 22.0002 10.56 20.8502 10.56 18.6702V16.7702C10.57 14.5802 9.42 13.4302 7.24 13.4302Z" ></path>
                    </svg>
                </MenuItem>
                <MenuItem link="/my-license" name="Lisensi Saya">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M13.25 12C13.9404 12 14.5 11.4404 14.5 10.75C14.5 10.0596 13.9404 9.5 13.25 9.5C12.5596 9.5 12 10.0596 12 10.75C12 11.4404 12.5596 12 13.25 12Z" ></path>
                        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.89 13.47C14.86 14.49 13.39 14.81 12.09 14.4L11.03 15.45C10.94 15.54 10.78 15.54 10.68 15.45L9.71 14.48C9.57 14.34 9.33 14.34 9.18 14.48C9.03 14.62 9.04 14.86 9.18 15.01L10.15 15.98C10.25 16.08 10.25 16.24 10.15 16.33L9.74 16.74C9.57 16.92 9.24 17.03 9 17L7.91 16.85C7.55 16.8 7.22 16.46 7.16 16.1L7.01 15.01C6.97 14.77 7.09 14.44 7.25 14.27L9.6 11.92C9.2 10.62 9.51 9.15 10.54 8.12C12.01 6.65 14.41 6.65 15.89 8.12C17.37 9.59 17.37 11.99 15.89 13.47Z" ></path>
                    </svg>
                </MenuItem>
            </div>
            <hr className="border-menu hidden md:block" />
            <div className="my-3 md:my-0"></div>
            <div className="flex flex-col gap-3 justify-between">
                <MenuItem link="/tutorial" name="Tutorial" >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M16.83 15.6402C17.5 15.2002 18.38 15.6802 18.38 16.4802V17.7702C18.38 19.0402 17.39 20.4002 16.2 20.8002L13.01 21.8602C12.45 22.0502 11.54 22.0502 10.99 21.8602L7.8 20.8002C6.6 20.4002 5.62 19.0402 5.62 17.7702V16.4702C5.62 15.6802 6.5 15.2002 7.16 15.6302L9.22 16.9702C10.01 17.5002 11.01 17.7602 12.01 17.7602C13.01 17.7602 14.01 17.5002 14.8 16.9702L16.83 15.6402Z" ></path>
                        <path d="M19.98 6.46006L13.99 2.53006C12.91 1.82006 11.13 1.82006 10.05 2.53006L4.03002 6.46006C2.10002 7.71006 2.10002 10.5401 4.03002 11.8001L5.63002 12.8401L10.05 15.7201C11.13 16.4301 12.91 16.4301 13.99 15.7201L18.38 12.8401L19.75 11.9401V15.0001C19.75 15.4101 20.09 15.7501 20.5 15.7501C20.91 15.7501 21.25 15.4101 21.25 15.0001V10.0801C21.65 8.79006 21.24 7.29006 19.98 6.46006Z" ></path>
                    </svg>
                </MenuItem>
                <MenuItem link="/lisensi-saya" name="Lisensi Saya">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M20.36 12.7301C19.99 12.7301 19.68 12.4501 19.64 12.0801C19.4 9.88007 18.22 7.90007 16.4 6.64007C16.07 6.41007 15.99 5.96007 16.22 5.63007C16.45 5.30007 16.9 5.22007 17.23 5.45007C19.4 6.96007 20.8 9.32007 21.09 11.9301C21.13 12.3301 20.84 12.6901 20.44 12.7301C20.41 12.7301 20.39 12.7301 20.36 12.7301Z" ></path>
                        <path d="M3.73998 12.7802C3.71998 12.7802 3.68998 12.7802 3.66998 12.7802C3.26998 12.7402 2.97998 12.3802 3.01998 11.9802C3.28998 9.3702 4.66998 7.0102 6.81998 5.4902C7.13998 5.2602 7.59998 5.3402 7.82998 5.6602C8.05998 5.9902 7.97998 6.4402 7.65998 6.6702C5.85998 7.9502 4.68998 9.9302 4.46998 12.1202C4.42998 12.5002 4.10998 12.7802 3.73998 12.7802Z" ></path>
                        <path d="M15.99 21.0998C14.76 21.6898 13.44 21.9898 12.06 21.9898C10.62 21.9898 9.24998 21.6698 7.96998 21.0198C7.60998 20.8498 7.46998 20.4098 7.64998 20.0498C7.81998 19.6898 8.25998 19.5498 8.61998 19.7198C9.24998 20.0398 9.91998 20.2598 10.6 20.3898C11.52 20.5698 12.46 20.5798 13.38 20.4198C14.06 20.2998 14.73 20.0898 15.35 19.7898C15.72 19.6198 16.16 19.7598 16.32 20.1298C16.5 20.4898 16.36 20.9298 15.99 21.0998Z" ></path>
                        <path d="M12.05 2.00977C10.5 2.00977 9.22998 3.26977 9.22998 4.82977C9.22998 6.38977 10.49 7.64977 12.05 7.64977C13.61 7.64977 14.87 6.38977 14.87 4.82977C14.87 3.26977 13.61 2.00977 12.05 2.00977Z" ></path>
                        <path d="M5.04998 13.8701C3.49998 13.8701 2.22998 15.1301 2.22998 16.6901C2.22998 18.2501 3.48998 19.5101 5.04998 19.5101C6.60998 19.5101 7.86998 18.2501 7.86998 16.6901C7.86998 15.1301 6.59998 13.8701 5.04998 13.8701Z" ></path>
                        <path d="M18.95 13.8701C17.4 13.8701 16.13 15.1301 16.13 16.6901C16.13 18.2501 17.39 19.5101 18.95 19.5101C20.51 19.5101 21.77 18.2501 21.77 16.6901C21.77 15.1301 20.51 13.8701 18.95 13.8701Z" ></path>
                    </svg>
                </MenuItem>
                <MenuItem link="/afliasi" name="Afliasi">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M22 9.75C21.59 9.75 21.25 9.41 21.25 9V7C21.25 4.42 19.58 2.75 17 2.75H7C4.42 2.75 2.75 4.42 2.75 7V9C2.75 9.41 2.41 9.75 2 9.75C1.59 9.75 1.25 9.41 1.25 9V7C1.25 3.56 3.56 1.25 7 1.25H17C20.44 1.25 22.75 3.56 22.75 7V9C22.75 9.41 22.41 9.75 22 9.75Z" ></path>
                        <path d="M17 22.75H7C3.56 22.75 1.25 20.44 1.25 17V15C1.25 14.59 1.59 14.25 2 14.25C2.41 14.25 2.75 14.59 2.75 15V17C2.75 19.58 4.42 21.25 7 21.25H17C19.58 21.25 21.25 19.58 21.25 17V15C21.25 14.59 21.59 14.25 22 14.25C22.41 14.25 22.75 14.59 22.75 15V17C22.75 20.44 20.44 22.75 17 22.75Z" ></path>
                        <path d="M16.4 8.20996L12.64 6.17996C12.24 5.96996 11.77 5.96996 11.37 6.17996L7.59997 8.20996C7.32997 8.35996 7.15997 8.64996 7.15997 8.96996C7.15997 9.29996 7.32997 9.58996 7.59997 9.73996L11.36 11.77C11.56 11.88 11.78 11.93 12 11.93C12.22 11.93 12.44 11.88 12.64 11.77L16.4 9.73996C16.67 9.58996 16.84 9.29996 16.84 8.96996C16.84 8.64996 16.67 8.35996 16.4 8.20996Z" ></path>
                        <path d="M10.74 12.4699L7.24 10.7199C6.97 10.5899 6.66 10.5999 6.4 10.7599C6.15 10.9199 6 11.1899 6 11.4899V14.7999C6 15.3699 6.32 15.8899 6.83 16.1399L10.33 17.8899C10.45 17.9499 10.58 17.9799 10.72 17.9799C10.88 17.9799 11.03 17.9399 11.17 17.8499C11.43 17.6899 11.58 17.4199 11.58 17.1199V13.8099C11.57 13.2399 11.26 12.7299 10.74 12.4699Z" ></path>
                        <path d="M17.59 10.7599C17.33 10.5999 17.02 10.5899 16.75 10.7199L13.25 12.4699C12.74 12.7299 12.42 13.2399 12.42 13.8099V17.1199C12.42 17.4199 12.57 17.6899 12.83 17.8499C12.97 17.9399 13.12 17.9799 13.28 17.9799C13.41 17.9799 13.54 17.9499 13.67 17.8899L17.17 16.1399C17.68 15.8799 18 15.3699 18 14.7999V11.4899C18 11.1899 17.85 10.9199 17.59 10.7599Z" ></path>
                    </svg>
                </MenuItem>
            </div>
        </>
    )
}

export default Menu