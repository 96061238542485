import { Link } from "react-router-dom"

const ButtonHelp = () => {
    return (
        <Link to="#" >
            <div className="border rounded-full px-5 py-1 border-[#58667E] text-[#58667E] flex items-center justify-center mr-6 text-sm font-medium gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Bantuan
            </div>
        </Link>
    )
}

export default ButtonHelp