import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

const Layout = ({ children }) => {
    return (
        <div className="w-100 h-100 mt-4 mx-7 flex flex-nowrap">
            <Sidebar />
            <div className="w-full md:pl-14">
                <Header />
                <main>{children}</main>
            </div>
        </div>
    )
}

export default Layout