import {createSlice} from "@reduxjs/toolkit";

const licenseSlice = createSlice({
    name: 'license',
    initialState: {
        licenses: null,
        lastKey: null,
        count: 0,
        scannedCount: 0,
        pageNumbers: [1],
    },
    reducers: {
        setLicenses: (state, action) => {
            state.licenses = action.payload.data;
            state.lastKey = action.payload.lastKey;
            state.count = action.payload.count;
            state.scannedCount = action.payload.scannedCount;
        },
        setPageNumbers: (state, action) => {
            if (state.pageNumbers.indexOf(action.payload) === -1) {
                state.pageNumbers.push(action.payload)
            }
        },
        resetPageNumbers: (state) => {
            state.pageNumbers = [1];
        }
    }
})

export const {setLicenses, setPageNumbers, resetPageNumbers} = licenseSlice.actions;

export const selectCurrentUser = state => state.license.licenses;

export default licenseSlice.reducer;