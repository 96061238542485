import { useState } from "react";
import Gravatar from "react-gravatar";
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from "../../../../../features/auth/authSlice";

const DropdownUser = () => {
    const [dropdownShow, setDropdownShow] = useState(false);
    const userData = useSelector((state) => state.auth.user)
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logOut());
        window.location = 'http://auth.lokuswp.id/oauth2/logout?client_id=2840125e-c04d-4052-9790-b70828ba9a9a';
    }

    return (
        <div className="relative inline-block text-left">
            <div className="flex items-center flex-row-reverse">
                <div className="hover:bg-blue-200 rounded-full p-2 hover:transition-all ease-in-out duration-300 avatar z-10">
                    <button
                        type="button"
                        className="rounded-full bg-[#B1D0FE] w-12 h-12 flex justify-center items-center"
                        onClick={() => setDropdownShow(!dropdownShow)}
                    >
                        <Gravatar email={userData.email} className="rounded-full" />
                    </button>
                </div>
                <span className="text-sm bg-blue-200 rounded-l-full px-2 py-1 avatar-name absolute w-32 left-0 hidden md:block">Hello, {`${userData.firstName} ${userData.lastName}`}</span>
                <span className="text-sm bg-white rounded-l-full px-2 py-1 absolute w-32 left-0 h-20 hidden md:block"></span>
            </div>

            {dropdownShow &&
                <div
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                    style={{ transition: 'all 3s ease-in-out' }}
                >
                    <div className="py-1" role="none">
                        <button
                            onClick={logout}
                            className="text-gray-700 block px-4 py-2 text-sm active:bg-gray-100 active:text-gray-900 w-full text-left"
                            role="menuitem" tabIndex="-1" id="menu-item-0">Logout</button>

                    </div>
                </div>
            }
        </div>
    )
}

export default DropdownUser