import Logo from './partials/Logo';
import Menu from './partials/Menu';

const Sidebar = () => {
    return (
        <div className="hidden md:block md:w-60 md:h-screen" aria-label="Sidebar">
            <div className="overflow-y-auto">
                <div className="mt-1 ml-[.1rem] mb-9">
                    <Logo />
                </div>
                <Menu />
            </div>
        </div>
    )
}

export default Sidebar